import cn from "classnames";
import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import { Clock, LogOut } from 'react-feather';
import { BrowserRouter as Router, Link, Route, Switch, useHistory } from "react-router-dom";
import { Brand } from "../Brand";
import { Scheduler } from '../Scheduler';
import { RateLimiter } from '../RateLimiter';
import { LinkedInCookie } from '../LinkedInCookie';

function Settings(setSearchInitialize, setActiveSearchId) {
    const routeHistory = useHistory();
    const [, , removeCookie] = useCookies();

    const [activeTab, setActiveTab] = useState("");

    function logoutHandler() {
        console.log("User has clicked on logout!");
        removeCookie('SEARCHEDIN_AUTH_TOKEN');
    }

    return (
        <Router>
            < div className='flex flex-col md:flex-row md:gap-40' >
                <div className='flex flex-row md:flex-col md:full md:px-2 bg-[#0057FF] rounded-md w-screen md:w-[20rem] lg:w-[24rem] h-10 md:h-full items-stretch'>
                    <div onClick={() => routeHistory.push('/dashboard')}>
                        <Brand
                            setSearchInitialize={setSearchInitialize}
                            setActiveSearchId={setActiveSearchId}
                            clickable={true}
                        />
                    </div>
                    <br></br>
                    <br></br>
                    <Link className="hidden md:block" to="/cookies">
                        <div
                            className='flex flex-row justify-between cursor-pointer text-[#3C7EFF] hover:text-blue-100'
                            onClick={() => setActiveTab('cookies')}
                        >
                            <span className='text-xl font-[Poppins]' > Cookies </span>
                        </div>
                    </Link>

                    <Link className="hidden md:block" to="/rate_limit">
                        <div
                            className='flex flex-row justify-between cursor-pointer text-[#3C7EFF] hover:text-blue-100'
                            onClick={() => setActiveTab('rate-limiter')}
                        >
                            <span className='text-xl font-[Poppins]' > Rate Limiter </span>
                        </div>
                    </Link>

                    <Link className="hidden md:block" to="/scheduler">
                        <div
                            className={cn(
                                'flex flex-row justify-between cursor-pointer hover:text-blue-100',
                                activeTab === 'scheduler' ? 'text-white' : 'text-[#3C7EFF]'
                            )}
                            onClick={() => setActiveTab('scheduler')}
                        >
                            <span className='text-xl font-[Poppins]' > Scheduler </span>
                            <Clock />
                        </div>
                    </Link>

                    <Link className="ml-auto md:ml-0" to="/login">
                        <div
                            className='flex flex-row justify-between cursor-pointer text-[#3C7EFF] hover:text-blue-100'
                            onClick={() => logoutHandler()}
                        >
                            <span className='text-xl font-[Poppins]'>
                                Logout
                            </span>
                            <LogOut />
                        </div>
                    </Link>
                </div>
                <div className='flex flex-row md:hidden px-1 bg-white rounded-md w-screen  h-10 justify-between items-stretch'>
                    <Link to="/cookies">
                        <div
                            className={cn(
                                'flex flex-row justify-between cursor-pointer',
                                activeTab === 'cookies' ? 'text-blue-100' : 'text-[#3C7EFF]'
                            )}
                            onClick={() => setActiveTab('cookies')}
                        >
                            <span className='text-xl font-[Poppins]' > Cookies </span>
                        </div>
                    </Link>

                    <Link to="/rate_limit">
                        <div
                            className={cn(
                                'flex flex-row justify-between cursor-pointer ',
                                activeTab === 'rate-limiter' ? 'text-blue-100' : 'text-[#3C7EFF]'
                            )}
                            onClick={() => setActiveTab('rate-limiter')}
                        >
                            <span className='text-xl font-[Poppins]' > Rate Limiter </span>
                        </div>
                    </Link>

                    <Link to="/scheduler">
                        <div
                            className={cn(
                                'flex flex-row justify-between cursor-pointer',
                                activeTab === 'scheduler' ? 'text-blue-100' : 'text-[#3C7EFF]'
                            )}
                            onClick={() => setActiveTab('scheduler')}
                        >
                            <span className='text-xl font-[Poppins]' > Scheduler </span>
                            {/* <Clock /> */}
                        </div>
                    </Link>
                </div>

                <Switch>
                    <Route path="/rate_limit">
                        <RateLimiter />
                    </Route>
                </Switch>
                <Switch>
                    <Route path="/scheduler">
                        <Scheduler />
                    </Route>

                    <Route path="/cookies">
                        <LinkedInCookie />
                    </Route>
                </Switch>
            </div>
        </Router>
    )
}

export default Settings;
import cn from "classnames";
import { Archive, CornerUpLeft, Star, Trash2 } from 'react-feather';
import linkedInImg from '../../assets/linkedin.png';
import { Iframe } from '../Iframe';

function TabContent({
    index, post, activeTab, selectHandler, starredOrArchivedPostHandler, displayPopup, style
}) {
    const live = activeTab === "Live";
    const starred = activeTab === "Starred";
    const archived = activeTab === "Archived";

    return (
        < div key={post.post_urn}
            className={cn(
                "group w-[18rem] md:w-[24rem] lg:w-[28rem] h-28",
                !displayPopup ? "relative" : ""
            )} 
            style = {style}
            >

            <Iframe postId={post.post_urn} postType={post.post_type} />

            {
                !displayPopup &&
                <div className={cn(
                    "absolute top-0 cursor-pointer w-[18rem] md:w-[24rem] lg:w-[28rem] rounded-lg h-28 block",
                    (!post.read) ? "bg-[#E2E2E2] opacity-40" : ""
                )}
                    onClick={() => selectHandler(index)}
                />
            }

            { !displayPopup && 
            <div className='flex absolute top-0.5 right-1 px-2.5 py-2 gap-2.5'>
                {
                    live &&
                    <Archive className='cursor-pointer' size={20}
                        onClick={() => {
                            starredOrArchivedPostHandler(post.id, { starred: false, archived: true });
                        }}
                    />
                }
                {
                    (live || starred) &&
                    <Star className='cursor-pointer' size={20}
                        fill={starred ? "black" : "none"}
                        onClick={
                            () => live
                                ? starredOrArchivedPostHandler(post.id, { starred: true, archived: false })
                                : starredOrArchivedPostHandler(post.id, { starred: false, archived: false })
                        }
                    />
                }
                {
                    archived &&
                    <>
                        <CornerUpLeft className='cursor-pointer' size={20}
                            onClick={() => {
                                starredOrArchivedPostHandler(post.id, { starred: false, archived: false });
                            }}
                        />
                        <Trash2 className='cursor-pointer' size={20}
                            onClick={() => starredOrArchivedPostHandler(post.id, { deleted: true })}
                        />
                    </>
                }
                <a href={`https://www.linkedin.com/feed/update/urn:li:${post.post_type}:${post.post_urn}`}
                    target="_blank" rel="noreferrer"
                >
                    <img src={linkedInImg} className='cursor-pointer' height={20} width={20} alt="" />
                </a>
            </div>}
        </div>
    )
}

export default TabContent;
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import cn from "classnames";
import React, { useState } from "react";
import homePageImg from '../../assets/homepage.png';
import { DEFAULT_ACTIVE_TAB, MARK_POSTS_URL, MARK_POST_URL, SEARCH_URL, TABS, POSTS_COUNT_URL } from "../../config";
import { TabContent } from '../AllTabs';
import './Tabs.css';
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { CompletePost } from "../CompletePost";
import ReactPaginate from 'react-paginate';

import { Checkbox } from "../Checkbox";
import { Archive, CornerUpLeft, RotateCw, Star, Trash2 } from "react-feather";


function Tabs({ searchInitialize, activeSearchId, displayPopup }) {
    const [activeTab, setActiveTab] = useState("");
    const [postData, setPostData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [msgAboutPosts, setMsgAboutPosts] = useState("");
    const [refresh, setRefresh] = useState(true);

    const [cookies] = useCookies();
    const [pageCount, setPageCount] = useState(1); //New
    const [currentPage, setcurrentPage] = useState(1); //New
    const [counts, setCounts] = useState({}); //New

    React.useEffect(() => {
        setActiveTab(DEFAULT_ACTIVE_TAB);
        setcurrentPage(1);
        setIsCheckAll(false);
        setIsCheck([]);
        setPostData([]);
        setMsgAboutPosts("Loading...");
        setCounts({ "Live": 0, "Starred": 0, "Archived": 0 });

        if (activeSearchId) {
            getPostsCountResult(activeSearchId);
            getSearchResult(activeSearchId, DEFAULT_ACTIVE_TAB);
        }
    }, [activeSearchId]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setRefresh(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, [refresh]);

    function getSearchResult(activeSearchId, active_tab, currentPage = 1, newSelectedIndex = null) {
        axios.get(
            `${SEARCH_URL}/${activeSearchId}`,
            {
                params: {
                    tab: active_tab.toLowerCase(),
                    page: currentPage
                },
                headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` }
            }
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {

                const noOfPosts = response.data.results.length;
                if (noOfPosts === 0) {
                    setMsgAboutPosts("No posts found!");
                } else if (noOfPosts > 0 && newSelectedIndex === null) {
                    newSelectedIndex = 0;
                } else if (noOfPosts > 1 && selectedIndex === noOfPosts) {
                    newSelectedIndex = selectedIndex - 1;
                }

                if (
                    noOfPosts > 0 &&
                    newSelectedIndex !== null &&
                    newSelectedIndex <= noOfPosts &&
                    !response.data.results[newSelectedIndex].read
                ) {
                    response.data.results[newSelectedIndex] = {
                        ...response.data.results[newSelectedIndex], read: true, tempRead: false
                    };
                }
                setSelectedIndex(newSelectedIndex);
                setPostData(response.data.results);
                setPageCount(response.data['pagination']['pages']);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    alert("Invalid payload! Please try again.")
                } else if (error.response.status === 403) {
                    alert("Invalid Linkedin Cookie! Please refresh page and save Linkedin cookie.")
                } else if (error.response.status === 404) {
                    setMsgAboutPosts("No Posts Found!");
                }
            }
            console.log("Error: ", error);
        });
    }

    function getPostsCountResult(activeSearchId) {
        axios.get(
            `${POSTS_COUNT_URL}/${activeSearchId}`,
            {
                headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` }
            }
        ).then((response) => {
            setCounts(response.data);
        }).catch((error) => {
            if (error.response.status === 404) {
                setMsgAboutPosts("No such search exists!");
            }
            console.log(error);
        });
    }

    const handlePageChange = async (selectedObject) => {
        let nextpage = selectedObject.selected + 1;
        setcurrentPage(nextpage);
        getSearchResult(activeSearchId, activeTab, nextpage);
    };

    function tabHandler(selectedTab) {
        const current_page = 1;
        setcurrentPage(current_page);
        setIsCheckAll(false);
        setIsCheck([]);
        setActiveTab(selectedTab);
        setPostData([]);
        setMsgAboutPosts("Loading...");

        getSearchResult(activeSearchId, selectedTab, { currentPage: current_page });
    }

    function markPostAsRead(post_id, { read = false }) {
        axios.patch(
            `${MARK_POST_URL}/${post_id}`,
            {
                post_id: post_id,
                read: read
            },
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            console.log(response.data.message);
        }).catch((error) => {
            console.log(error);
        });
    }

    function starredOrArchivedPostHandler(post_id, { starred = false, archived = false, deleted = false }) {
        if (archived) {
            notify('Archived!');
        } else if (starred) {
            notify('Starred!');
        } else if (deleted) {
            notify('Deleted!');
        } else if (!starred && !archived) {
            notify('Restored!');
        }
        axios.patch(
            `${MARK_POST_URL}/${post_id}`,
            { "starred": starred, "archived": archived, "deleted": deleted },
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            if (response.status === 200) {
                getPostsCountResult(activeSearchId);
                getSearchResult(activeSearchId, activeTab, currentPage, selectedIndex);
            }
        }).catch((error) => {
            console.log("starred Or Archived Post Handler Error: ", error);
        })
    }

    function bulkStarredOrArchivedPostHandler({ starred = false, archived = false, deleted = false }) {
        const length = isCheck.length;
        if (archived) {
            notify(`${length} Archived!`);
        } else if (starred) {
            notify(`${length} Starred!`);
        } else if (deleted) {
            notify(`${length} Deleted!`);
        } else if (!starred && !archived) {
            notify(`${length} Restored!`);
        }

        if (length > 0) {
            const postIds = isCheck.map((value) => parseInt(value.split('-')[0]));
            axios.patch(
                MARK_POSTS_URL,
                { "starred": starred, "archived": archived, "deleted": deleted, "post_ids": postIds },
                { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
            ).then((response) => {
                console.log("Bulk: ", response);
                if (response.status === 200) {
                    setIsCheckAll(false);
                    getPostsCountResult(activeSearchId);
                    getSearchResult(activeSearchId, activeTab, currentPage, selectedIndex);
                }
            }).catch((error) => {
                console.log("bulk Starred Or Archived Post Handler Error: ", error);
            })
        }
    }

    function refreshPosts() {
        if (refresh) {
            setRefresh(false);
            getPostsCountResult(activeSearchId);
            getSearchResult(activeSearchId, activeTab, currentPage, selectedIndex);
        }
    }

    function handleCheckboxClick(e) {
        const { id, checked } = e.target;
        if (checked && !isCheck.includes(id)) {
            setIsCheck([...isCheck, id]);
        } else if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    }

    function handleCheckboxSelectAll() {
        setIsCheckAll(!isCheckAll);
        setIsCheck(postData.map(post => `${post.id}-${post.post_urn}`));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    function notify(message) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            rtl: false,
            theme: "dark"
        });
    };

    return (
        <div className={cn(
            "Tabs h-screen md:h-full flex flex-col md:flex-row",
            searchInitialize ? "bg-[#F9F9F9]" : "bg-white"
        )}>
            {
                searchInitialize
                    ?
                    <>
                        <div className="grid grid-flow-row order-last md:order-none w-full md:w-[32rem]">
                            <ul className="nav flex flex-row place-self-center w-full md:w-[24rem] px-2 md:px-0 my-2 md:my-8" >
                                {
                                    TABS.map((tab) =>
                                        <li
                                            key={tab}
                                            className={cn(
                                                "w-full text-sm md:text-lg",
                                                activeTab === tab
                                                    ? "bg-[#0057FF] text-white"
                                                    : "bg-[#F9F9F9]"
                                            )}
                                            onClick={() => tabHandler(tab)}
                                        > {`${tab} (${counts[tab]})`}
                                        </li>
                                    )
                                }
                            </ul>

                            <div className="flex items-center gap-2 px-2 md:px-0 md:ml-12 lg:ml-6">
                                {
                                    postData.length > 0 &&
                                    <>
                                        <Checkbox
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            handleClick={handleCheckboxSelectAll}
                                            isChecked={isCheckAll}
                                        />
                                        <span> Select All </span>
                                        {
                                            activeTab === "Live" && isCheck.length > 0 &&
                                            <Archive className='cursor-pointer ml-8' size={20}
                                                onClick={() => bulkStarredOrArchivedPostHandler({ archived: true })}
                                            />
                                        }
                                        {
                                            (activeTab === "Live" || activeTab === "Starred") && isCheck.length > 0 &&
                                            < Star className='cursor-pointer ml-4' size={20}
                                                fill={activeTab === "Starred" ? "black" : "none"}
                                                onClick={
                                                    () => activeTab === "Live"
                                                        ? bulkStarredOrArchivedPostHandler({ starred: true, archived: false })
                                                        : bulkStarredOrArchivedPostHandler({ starred: false, archived: false })
                                                }
                                            />
                                        }
                                        {
                                            activeTab === "Archived" && isCheck.length > 0 &&
                                            <>
                                                <CornerUpLeft className='cursor-pointer ml-8' size={20}
                                                    onClick={() => bulkStarredOrArchivedPostHandler({
                                                        archived: false, starred: false
                                                    })}
                                                />
                                                <Trash2 className='cursor-pointer ml-4' size={20}
                                                    onClick={() => bulkStarredOrArchivedPostHandler({ deleted: true })}
                                                />
                                            </>
                                        }

                                    </>

                                }
                                <div
                                    className="flex flex-row items-center justify-center gap-1 cursor-pointer hover:bg-slate-200 w-1/5 h-full mr-0 ml-auto rounded-md"
                                    disabled={!refresh}
                                    onClick={refreshPosts}
                                >
                                    <RotateCw size={15} strokeWidth={3} color='#0057FF' />
                                    <span className="text-[#0057FF]">
                                        Refresh
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-row md:flex-col items-center text-black gap-2 md:h-full overflow-auto w-full md:w-[32rem] px-2 md:px-0 pb-3 md:pb-0 mt-4">
                                {
                                    postData.length > 0
                                        ?
                                        postData.map((post, index) => post.post_type && post.post_urn &&
                                            <div
                                                key={`${post.id}-${post.post_urn}`}
                                                className="flex flex-row gap-2"
                                            >
                                                <Checkbox
                                                    key={`checkbox-${post.post_urn}`}
                                                    type="checkbox"
                                                    name={post.post_urn}
                                                    id={`${post.id}-${post.post_urn}`}
                                                    handleClick={handleCheckboxClick}
                                                    isChecked={isCheck.includes(`${post.id}-${post.post_urn}`)}
                                                />
                                                <TabContent
                                                    key={post.post_urn}
                                                    index={index}
                                                    post={post}
                                                    activeTab={activeTab}
                                                    selectHandler={(index) => {
                                                        setSelectedIndex(index);
                                                        if (!postData[index].read) {
                                                            markPostAsRead(postData[index].id, { read: true });
                                                            postData[index] = { ...postData[index], read: true };
                                                        }
                                                    }}
                                                    starredOrArchivedPostHandler={starredOrArchivedPostHandler}
                                                    displayPopup={displayPopup}
                                                    style={{  border: selectedIndex === index ? '2px solid #0057FF' : 'none', borderRadius: 10 }}
                                                />
                                            </div>
                                        )
                                        :
                                        <div className={cn(
                                            "flex items-center text-2xl text-red-500",
                                            msgAboutPosts === "Loading..." ? "animate-pulse" : ""
                                        )}>
                                            {msgAboutPosts}
                                        </div>
                                }
                                <ToastContainer />
                            </div>

                            <div className="flex flex-row items-center content-center justify-center md:mt-4 mb-4">
                                {
                                    pageCount > 1 &&
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRange={2}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        previousLabel={'prev'}
                                        nextLabel={'next'}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        forcePage={currentPage-1}
                                    />
                                }
                            </div>
                        </div>
                        { !displayPopup &&
                            Array.isArray(postData) && postData.length > 0 && selectedIndex !== null &&
                            postData[selectedIndex].post_type && postData[selectedIndex].post_urn &&
                            <CompletePost
                                activeTab={activeTab}
                                starredOrArchivedPostHandler={starredOrArchivedPostHandler}
                                displayPopup={displayPopup}
                                post={postData[selectedIndex]}
                            />
                        }
                    </>
                    :
                    <img
                        src={homePageImg}
                        className="flex justify-center pl-8 md:pl-20 lg:pl-40 pt-10 md:pt-20 w-screen h-2/3 md:h-screen"
                        alt=""
                    />
            }
        </div >
    );
}
export default Tabs;
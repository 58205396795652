import axios from "axios";
import cn from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from 'react';
import { RotateCw, X } from 'react-feather';
import * as Yup from "yup";
import { DAILY_REFRESH_LIMIT_PER_KEYWORD, HISTORY_DETAILS_URL, WEEKLY_REFRESH_LIMIT_PER_KEYWORD } from "../../config";
import { useCookies } from "react-cookie";

function LinkedInLink({ editHistoryId, setEditHistoryId, setDisplayPopup, newSearchHandler }) {
    const [hasLoaded, setHasLoaded] = useState(!!editHistoryId ? false : true);
    const [showLoader, setShowLoader] = useState(false);
    const [linkText, setLinkText] = useState("");
    const [link, setLink] = useState("");
    const [dayLimit, setDayLimit] = useState(DAILY_REFRESH_LIMIT_PER_KEYWORD);
    const [weekLimit, setWeekLimit] = useState(WEEKLY_REFRESH_LIMIT_PER_KEYWORD);
    const [cookies] = useCookies();

    React.useEffect(() => {
        if (editHistoryId) {
            axios.get(
                `${HISTORY_DETAILS_URL}/${editHistoryId}`,
                { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
            ).then((response) => {
                if (response.status === 200) {
                    setHasLoaded(true);
                    setLinkText(response.data.linkText);
                    setLink(response.data.link);
                    setDayLimit(response.data.dayLimit);
                    setWeekLimit(response.data.weekLimit);
                }
            })
        }
    }, [editHistoryId]);

    return (
        hasLoaded &&
        <div className={cn(
            'absolute flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-[#000000b3]',
        )}>
            <div className={cn(
                "border border-black rounded-lg bg-slate-200",
            )}>
                <X
                    strokeWidth={1.5}
                    className="float-right cursor-pointer mx-1 my-2 inset-x-4"
                    onClick={() => {
                        setEditHistoryId(null);
                        setDisplayPopup(false);
                    }}
                />
                <div className='grid place-content-center rounded-2xl mx-4 mt-8 mb-4'>
                    <Formik
                        validationSchema={Yup.object({
                            linkText: Yup.string()
                                .required("Link text is a required field"),

                            link: Yup.string()
                                .required("Link is a required field"),

                            dayLimit: Yup.number()
                                .required("Day limit is a required field")
                                .min(1, "Day limit must be greater than or equal to 1")
                                .max(4, "Day limit must be less than or equal to 4"),

                            weekLimit: Yup.number()
                                .required("Week limit is a required field")
                                .min(1, "Week limit must be greater than or equal to 1")
                                .max(20, "Week limit must be less than or equal to 20")
                        })}

                        initialValues={{
                            linkText: linkText,
                            link: link,
                            dayLimit: dayLimit,
                            weekLimit: weekLimit
                        }}

                        onSubmit={(values) => {
                            setShowLoader(true);
                            newSearchHandler({
                                linkText: values.linkText,
                                link: values.link,
                                dayLimit: values.dayLimit,
                                weekLimit: values.weekLimit
                            });
                        }}
                    >
                        < Form className="grid grid-cols-1" noValidate>
                            <label htmlFor="linkText" className='font-mono text-sm'>
                                Link Text
                            </label>
                            <Field
                                type="text"
                                name="linkText"
                                placeholder="Enter text for link"
                                className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md w-[14rem] md:w-[22rem] lg:w-[28rem] h-8"
                            />
                            <p className="my-2 text-sm text-red-600 font-mono">
                                <ErrorMessage name="linkText"></ErrorMessage>
                            </p>

                            <label htmlFor="link" className='font-mono text-sm'>
                                Link
                            </label>
                            <Field
                                type="text"
                                name="link"
                                placeholder="Copy and paste link here"
                                className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md w-[14rem] md:w-[22rem] lg:w-[28rem] h-8"
                            />
                            <p className="my-1 text-sm text-red-600 font-mono">
                                <ErrorMessage name="link"></ErrorMessage>
                            </p>

                            <div className="flex flex-col gap-2">
                                <div>
                                    <label htmlFor="dayLimit" className='font-mono text-sm'>
                                        Daily Refresh Limit
                                    </label>
                                    <Field
                                        type="number"
                                        name="dayLimit"
                                        className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md w-[3rem] h-8 ml-4"
                                    />
                                    <p className="my-1 text-sm text-red-600 font-mono">
                                        <ErrorMessage name="dayLimit"></ErrorMessage>
                                    </p>
                                </div>

                                <div>

                                    <label htmlFor="weekLimit" className='font-mono text-sm'>
                                        Weekly Refresh Limit
                                    </label>
                                    <Field
                                        type="number"
                                        name="weekLimit"
                                        className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md w-[3rem] h-8 ml-4"
                                    />
                                    <p className="my-1 text-sm text-red-600 font-mono">
                                        <ErrorMessage name="weekLimit"></ErrorMessage>
                                    </p>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className={cn(
                                    "cursor-pointer bg-[#0057FF] rounded-md w-20 h-8 place-self-center text-white mt-2",
                                    showLoader && "flex gap-2 w-36 p-1"
                                )}
                            >
                                {
                                    showLoader
                                        ?
                                        <>
                                            <RotateCw className="animate-spin" />
                                            Processing...
                                        </>
                                        :
                                        <>
                                            Search
                                        </>
                                }
                            </button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default LinkedInLink;
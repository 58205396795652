/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState } from 'react';
import { COOKIES_URL } from '../../config';
import { LinkedInCookie } from '../LinkedInCookie';
import { Navigation } from '../Navigation';
import { Tabs } from '../Tabs';
import { useCookies } from 'react-cookie';
import Footer from '../Footer/Footer';


function Dashboard() {
    const [searchInitialize, setSearchInitialize] = useState(false);
    const [activeSearchId, setActiveSearchId] = useState(null);
    const [cookieExists, setCookieExists] = useState(false);
    const [displayPopup, setDisplayPopup] = useState(false);

    const [cookies, , removeCookie] = useCookies();

    React.useEffect(() => {
        axios.get(
            COOKIES_URL,
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then(() => {
            setCookieExists(true);
        }).catch((err) => {
            if (err.response.status === 401) {
                removeCookie('SEARCHEDIN_AUTH_TOKEN');
            } else {
                setCookieExists(false);
            }
        })
    }, []);

    return (
        <div className='flex flex-col md:flex-row justify-between'>
            <Navigation
                setSearchInitialize={(flag) => { setSearchInitialize(flag) }}
                activeSearchId={activeSearchId}
                setActiveSearchId={setActiveSearchId}
                displayPopup={displayPopup}
                setDisplayPopup={setDisplayPopup}
                cookieExists={cookieExists}
            />
            {
                cookieExists
                    ?
                    <Tabs
                        searchInitialize={searchInitialize}
                        activeSearchId={activeSearchId}
                        displayPopup={displayPopup}
                    />
                    :
                    <LinkedInCookie
                        setCookieExists={setCookieExists}
                    />
            }
            <Footer/>
        </div>
    )
}

export default Dashboard;
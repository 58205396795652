import React from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import './App.css';
import { Dashboard } from './components/Dashboard';
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from './components/Login';
import { Register } from "./components/Register";
import { ResetPassword } from "./components/ResetPassword";
import { Settings } from "./components/Settings";


function App() {
  const [cookies] = useCookies();

  function PrivateRoute({ children, ...rest }) {
    const isAuthenticated = cookies["SEARCHEDIN_AUTH_TOKEN"] ? true : false;

    return (
      <Route
        {...rest}
        render={() => {
          return isAuthenticated === true ? (
            children
          ) : (
            <Redirect to="/login" />
          );
        }}
      />
    );
  }

  return (
    <Router>
      <div className="flex w-screen h-screen relative">
        <Switch>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/register">
            <Register />
          </Route>

          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>

          <Route path="/reset-password/:token">
            <ResetPassword />
          </Route>

          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute exact path="/settings">
            <Settings />
          </PrivateRoute>

          <Route path="*">
            <Redirect to="dashboard" />
          </Route>

        </Switch>
      </div>
    </Router>
  );
}

export default App;

import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { REGISTER_URL } from "../../config";
import { Brand } from "../Brand";


function Register() {
    const [registerSuccessMessage, setRegisterSuccessMessage] = useState("");
    const [registerErrorMessage, setRegisterErrorMessage] = useState("");

    function handleCreateAccount({ email, password }) {
        axios.post(
            REGISTER_URL,
            { email: email, password: password }
        ).then((response) => {
            if (response.status === 201) {
                setRegisterSuccessMessage("User successfully registered!");
                setRegisterErrorMessage("");
                console.log("Account created successfully! (TODO: Redirect to login page)");
            }
        }).catch((error) => {
            if (error.response.status === 409) {
                setRegisterErrorMessage("Email already in use!");
            } else if (error.response.status === 403) {
                setRegisterErrorMessage(error.response.data);
            }
            setRegisterSuccessMessage("");
            console.log(error);
        });
    }

    return (
        <div className="w-full h-screen bg-[#0057FF] grid place-content-center font-[Comfortaa]">
            <Brand />

            <div className="bg-white w-96 h-76 p-12 pb-4 rounded-xl">
                <Formik
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .required("Email is a required field")
                            .email("Invalid email format"),

                        password: Yup.string()
                            .required("Password is a required field")
                            .min(8, "Password must be at least 8 characters")
                            .max(16, "Password can have maximum 16 characters")
                    })}

                    initialValues={{
                        email: "",
                        password: ""
                    }}

                    onSubmit={(values) => {
                        handleCreateAccount({ email: values.email, password: values.password });
                    }}
                >
                    < Form className="grid grid-cols-1" noValidate>
                        <span className="flex justify-center mb-4 text-3xl text-[#0057FF]">
                            Sign Up
                        </span>

                        <label htmlFor="email">Email</label>
                        <Field
                            type="email"
                            name="email"
                            placeholder="Enter email id"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                            id="email"
                        />
                        <p className="my-2 text-sm text-red-600 font-serif">
                            <ErrorMessage name="email"></ErrorMessage>
                        </p>

                        <label htmlFor="password">Password</label>
                        <Field
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                        />
                        <p className="my-1 text-sm text-red-600 font-serif">
                            <ErrorMessage name="password"></ErrorMessage>
                        </p>

                        <span className="text-red-600">
                            {registerErrorMessage}
                        </span>

                        <button
                            type="submit"
                            className="cursor-pointer bg-[#0057FF] rounded-md w-24 h-9 place-self-center text-white mt-2"
                        >
                            SIGN UP
                        </button>
                    </Form>
                </Formik>

                {
                    registerSuccessMessage &&
                    <div className="flex justify-center text-green-600">
                        {registerSuccessMessage}
                    </div>
                }

                <div className="flex justify-center">
                    Already have an account?
                    <Link to="/login"
                        className="cursor-pointer text-[#0057FF] mx-1"
                    >
                        Sign In
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default Register;
import React from 'react';
import { useHistory } from "react-router-dom";
import codebaseImg from '../../assets/codebase_transparent.png';

function Footer() {
    const routeHistory = useHistory();

    return (
        <div className='flex bottom-0 md:hidden bg-[#0057FF]'>
            <div
                className='flex flex-row justify-between cursor-pointer text-[#3C7EFF] hover:text-blue-100 pl-1'
                onClick={() => routeHistory.push('/settings')}
            >
                <span className='text-xl md:text-2xl font-[Poppins]' >
                    Settings
                </span>
            </div>

            <div className='flex flex-row items-center mg-1 ml-auto justify-center content-center pr-1'>
                <span className='text-sm md:text-base text-white'>Powered by</span>
                <a href='https://www.codebase.com/' target="_blank" rel="noreferrer">
                    <img src={codebaseImg} className='w-20 h-8 md:w-40 md:h-10' alt="" />
                </a>
            </div>
        </div>
    )
}

export default Footer;








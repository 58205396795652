import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { LOGIN_URL } from "../../config";
import { Brand } from "../Brand";

function Login() {
    const [, setCookies] = useCookies();
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    if (redirectToReferrer) {
        return <Redirect to="/dashboard" />;
    }

    function handleLogin({ email, password }) {
        axios.post(
            LOGIN_URL,
            { email: email, password: password }
        ).then((response) => {
            if (response.status === 200) {
                setCookies(
                    "SEARCHEDIN_AUTH_TOKEN",
                    response.data.token,
                    {
                        secure: true,
                        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                    }
                );
                setRedirectToReferrer(true);
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                setLoginErrorMessage("Invalid email or password!");
            }
            console.log(err);
        });
    }

    return (
        <div className="w-full h-screen bg-[#0057FF] grid place-content-center font-[Comfortaa]">
            <Brand />

            <div className="bg-white w-96 h-76 p-12 pb-4 rounded-xl">
                <Formik
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .required("Email is a required field")
                            .email("Invalid email format"),

                        password: Yup.string()
                            .required("Password is a required field")
                            .min(8, "Password must be at least 8 characters")
                            .max(16, "Password can have maximum 16 characters")
                    })}

                    initialValues={{
                        email: "",
                        password: ""
                    }}

                    onSubmit={(values) => {
                        handleLogin({ email: values.email, password: values.password });
                    }}
                >
                    < Form className="grid grid-cols-1" noValidate>
                        <span className="flex justify-center mb-4 text-3xl text-[#0057FF]">
                            Sign In
                        </span>

                        <label htmlFor="email">Email</label>
                        <Field
                            type="email"
                            name="email"
                            placeholder="Enter email id"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                            id="email"
                        />
                        <p className="my-2 text-sm text-red-600 font-serif">
                            <ErrorMessage name="email"></ErrorMessage>
                        </p>

                        <label htmlFor="password">Password</label>
                        <Field
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                        />
                        <p className="my-1 text-sm text-red-600 font-serif">
                            <ErrorMessage name="password"></ErrorMessage>
                        </p>

                        <span className="text-red-600">
                            {loginErrorMessage}
                        </span>

                        <button
                            type="submit"
                            className="cursor-pointer bg-[#0057FF] rounded-md w-24 h-9 place-self-center text-white mt-2"
                        >
                            SIGN IN
                        </button>
                    </Form>
                </Formik>

                <div className="flex justify-center cursor-pointer text-xs text-[#0057FF] mt-2 mb-1" >
                    <Link to="/forgot-password">
                        FORGOT PASSWORD?
                    </Link>
                </div>

                <div className="flex justify-center">
                    Don't have an account?
                    <Link
                        to="/register"
                        className="cursor-pointer text-[#0057FF] mx-1"
                    >
                        Create Account
                    </Link>
                </div>
            </div>
        </div >
    );
}

export default Login;
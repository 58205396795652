import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Brand } from "../Brand";
import { FORGOT_PASSWORD_URL } from "../../config";

function ForgotPassword() {

    const [forgotPasswordSuccessMessage, setForgotPasswordSuccessMessage] = useState("");
    const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState("");

    return (
        <div className="w-full h-screen bg-[#0057FF] grid place-content-center font-[Comfortaa]">
            <Brand />
            <div className="bg-white w-[28rem] h-76 p-12 pb-4 rounded-xl">
                <Formik
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .required("Email is a required field")
                            .email("Invalid email format")
                    })}

                    initialValues={{
                        email: ""
                    }}

                    onSubmit={(values) => {
                        axios.get(
                            `${FORGOT_PASSWORD_URL}/${values.email}`
                        ).then((response) => {
                            if (response.status === 200) {
                                setForgotPasswordErrorMessage("");
                                setForgotPasswordSuccessMessage("Reset link sent to your email id!");
                            }
                        }).catch((err) => {
                            if (err.response.status === 404) {
                                setForgotPasswordSuccessMessage("");
                                setForgotPasswordErrorMessage("User does not exists!");
                            }
                        })
                    }}
                >
                    <Form className="grid grid-cols-1" noValidate>
                        <span className="flex justify-center mb-4 text-3xl text-[#0057FF]">
                            Forgot Password
                        </span>

                        <label htmlFor="password">Email</label>
                        <Field
                            type="email"
                            name="email"
                            placeholder="Enter associated email id"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                        />
                        <p className="my-1 text-sm text-red-600 font-serif">
                            <ErrorMessage name="email"></ErrorMessage>
                        </p>

                        <div className="flex justify-center text-green-600">
                            {forgotPasswordSuccessMessage}
                        </div>
                        <span className="text-red-600">
                            {forgotPasswordErrorMessage}
                        </span>

                        <button
                            type="submit"
                            className="cursor-pointer bg-blue-500 hover:bg-[#0057FF] rounded-md w-48 h-9 place-self-center text-white mt-2"
                        >
                            Get Reset Link
                        </button>
                    </Form>
                </Formik>

                <div className="flex justify-center cursor-pointer text-[#0057FF] mx-1">
                    <Link to="/login">
                        Return to login
                    </Link>
                </div>
            </div>
        </div >
    );
}

export default ForgotPassword;
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HelpCircle, RotateCw } from "react-feather";
import ReactTooltip from 'react-tooltip';
import * as Yup from "yup";
import { COOKIES_URL } from "../../config";
import { useCookies } from "react-cookie";
import { useState } from "react";
import cn from "classnames";


function LinkedInCookie({ setCookieExists }) {
    const [showLoader, setShowLoader] = useState(false);
    const [cookies] = useCookies();

    return (
        <div className="h-1/2 p-20 w-screen md:h-screen md:p-40">
            <Formik
                validationSchema={Yup.object({
                    cookie: Yup.string()
                        .required("LinkedIn Session Cookie is a required field"),

                    sessionId: Yup.string()
                        .required("LinkedIn Session Id is a required field")
                })}

                initialValues={{
                    cookie: "",
                    sessionId: ""
                }}

                onSubmit={(values) => {
                    setShowLoader(true);
                    axios.patch(
                        COOKIES_URL,
                        { cookie: values.cookie, sessionId: values.sessionId },
                        { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
                    ).then((response) => {
                        if (response.status === 200) {
                            setShowLoader(false);
                            setCookieExists(true);
                            console.log("Cookie saved successfully");
                        } else {
                            console.log("Error saving cookie with status code: ", response.status);
                        }
                    }).catch(() => {
                        alert("Retry...");
                    })
                }}
            >
                < Form className="grid grid-cols-1" noValidate>
                    <div className="flex">
                        <label htmlFor="cookie" className='font-mono text-sm'>
                            Your LinkedIn session cookie
                        </label>
                        <div
                            data-tip="You can find your cookie manually<br>(the LinkedIn cookie's name is 'li_at')"
                            data-place="right"
                            data-type="dark"
                            data-multiline={true}
                            data-delay-hide='500'
                            className="ml-2"
                        >
                            <HelpCircle size={16} className="cursor-pointer" />
                            <ReactTooltip />
                        </div>
                    </div>
                    <Field
                        type="text"
                        name="cookie"
                        placeholder="Copy and paste LinkedIn Session Cookie"
                        className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md w-[14rem] md:w-[22rem] lg:w-[28rem] h-8 placeholder:text-xs placeholder:md:text-base"
                    />
                    <p className="my-2 text-sm text-red-600 font-mono">
                        <ErrorMessage name="cookie"></ErrorMessage>
                    </p>

                    <div className="flex">
                        <label htmlFor="sessionId" className='font-mono text-sm'>
                            Session Id
                        </label>
                        <div
                            data-tip="You can find your cookie manually<br>(the LinkedIn cookie's name is 'JSESSIONID')"
                            data-place="right"
                            data-type="dark"
                            data-multiline={true}
                            data-delay-hide='500'
                            className="ml-2"
                        >
                            <HelpCircle size={16} className="cursor-pointer" />
                            <ReactTooltip />
                        </div>
                    </div>
                    <Field
                        type="text"
                        name="sessionId"
                        placeholder="Copy and paste LinkedIn Session Id here"
                        className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md w-[14rem] md:w-[22rem] lg:w-[28rem] h-8 placeholder:text-xs placeholder:md:text-base"
                    />
                    <p className="my-1 text-sm text-red-600 font-mono">
                        <ErrorMessage name="sessionId"></ErrorMessage>
                    </p>

                    <button
                        type="submit"
                        className={cn(
                            "cursor-pointer bg-blue-500 hover:bg-[#0057FF] rounded-md w-20 h-8 text-white",
                            showLoader && "flex gap-2 p-1 h-9"
                        )}
                    >
                        {
                            showLoader &&
                            <RotateCw size={20} className="animate-spin mt-1 ml-1" />
                        } Save
                    </button>
                </Form>
            </Formik>
        </div>
    )
}

export default LinkedInCookie;
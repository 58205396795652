/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React from "react";
import { MARK_POST_URL } from "../../config";
import { useCookies } from 'react-cookie';
import { Archive, CornerUpLeft, Star, Trash2 } from 'react-feather';
import linkedInImg from '../../assets/linkedin.png';

function CompletePost({ activeTab,starredOrArchivedPostHandler,displayPopup,post, }) {
    const [cookies] = useCookies();
    const live = activeTab === "Live";
    const starred = activeTab === "Starred";
    const archived = activeTab === "Archived";

    React.useEffect(() => {
        if (!post.read || post.tempRead === false) {
            axios.patch(
                `${MARK_POST_URL}/${post.id}`,
                { "read": true },
                { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
            ).then((response) => {
                console.log(response.data);
                post.read = true;
            }).catch((error) => {
                console.log("Mark post read Error: ", error);
            })
        }
    }, [post.post_type, post.post_urn])

    return (
        <div className="flex relative order-first md:order-none items-center w-full md:w-[32rem] lg:w-[42rem] h-2/3 md:h-full p-2 md:p-6">
            <iframe
                id={`Iframe-${post.post_type}-${post.post_urn}`}
                className="h-full w-full rounded-lg"
                src={`https://www.linkedin.com/embed/feed/update/urn:li:${post.post_type}:${post.post_urn}`}
                title={`${post.post_type}:${post.post_urn}`}
                frameBorder={0}
                scrolling="yes"
                loading="lazy"
                // sandbox=''
                style={{ borderRadius: 8 }}
            />
            <div className='flex absolute md:top-6 md:right-10 top-2 right-4 px-2.5 py-2 gap-2.5'>
                
            {
                    live &&
                    <Archive className='cursor-pointer' size={20}
                        onClick={() => {
                            starredOrArchivedPostHandler(post.id, { starred: false, archived: true });
                        }}
                    />
                }
                {
                    (live || starred) &&
                    <Star className='cursor-pointer' size={20}
                        fill={starred ? "black" : "none"}
                        onClick={
                            () => live
                                ? starredOrArchivedPostHandler(post.id, { starred: true, archived: false })
                                : starredOrArchivedPostHandler(post.id, { starred: false, archived: false })
                        }
                    />
                }
                {
                    archived &&
                    <>
                        <CornerUpLeft className='cursor-pointer' size={20}
                            onClick={() => {
                                starredOrArchivedPostHandler(post.id, { starred: false, archived: false });
                            }}
                        />
                        <Trash2 className='cursor-pointer' size={20}
                            onClick={() => starredOrArchivedPostHandler(post.id, { deleted: true })}
                        />
                    </>
                }

                <a href={`https://www.linkedin.com/feed/update/urn:li:${post.post_type}:${post.post_urn}`}
                    target="_blank" rel="noreferrer"
                >
                    <img src={linkedInImg} className='cursor-pointer' height={20} width={20} alt="" />
                </a>
            </div>
        </div>
    )
}
export default CompletePost;

function Iframe({ postId, postType }) {
    return (
        <iframe
            id={postId}
            className="w-full mb-10 rounded-lg h-full"
            src={`https://www.linkedin.com/embed/feed/update/urn:li:${postType}:${postId}`}
            title={`${postType}-${postId}`}
            frameBorder={0}
            scrolling="no"
            loading="lazy"
            // sandbox=''
            // sandbox='allow-scripts'
            style={{ border: "1px solid #777", borderRadius: 8 }}
        />
    )

}

export default Iframe;
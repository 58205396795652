import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { RESET_PASSWORD_URL } from "../../config";
import { Brand } from "../Brand";


function ResetPassword() {

    const { token } = useParams();
    const history = useHistory();
    const [email, setEmail] = useState(null);
    const [resetErrorMessage, setResetErrorMessage] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('email')) {
            setEmail(searchParams.get('email'));
        } else {
            history.push('/login');
        }
    }, [token]);

    function handleResetPassword({ password, confirmPassword }) {
        axios.post(
            `${RESET_PASSWORD_URL}/${token}`,
            { email: email, password: password, confirmPassword: confirmPassword }
        ).then((response) => {
            if (response.status === 200) {
                setResetErrorMessage("Password reset successfully!");
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                setResetErrorMessage("Invalid token!");
            } else if (err.response.status === 403) {
                setResetErrorMessage("Invalid old password!");
            } else if (err.response.status === 404) {
                setResetErrorMessage("User does not exists!");
            } else {
                setResetErrorMessage("Something went wrong!");
            }
        });
    }

    return (
        <div className="w-full h-screen bg-[#0057FF] grid place-content-center font-[Comfortaa]">
            <Brand />
            <div className="bg-white w-[28rem] h-76 p-12 pb-4 rounded-xl">
                <Formik
                    validationSchema={Yup.object({
                        password: Yup.string()
                            .required("password is a required field")
                            .min(8, "Password must be at least 8 characters"),
                        confirmPassword: Yup.string()
                            .required("confirm password is a required field")
                            .min(8, "Password must be at least 8 characters")
                            .when("password", {
                                is: val => (val && val.length > 0 ? true : false),
                                then: Yup.string().oneOf(
                                    [Yup.ref("password")], "PASSWORDS MUST MATCH."
                                )
                            })
                    })}

                    initialValues={{
                        password: "",
                        confirmPassword: ""
                    }}

                    onSubmit={(values) => {
                        handleResetPassword({ password: values.password, confirmPassword: values.confirmPassword });
                    }}
                >
                    <Form className="grid grid-cols-1" noValidate>
                        <span className="flex justify-center mb-4 text-3xl text-[#0057FF]">
                            Reset Password
                        </span>

                        <label htmlFor="password">Password</label>
                        <Field
                            type="password"
                            name="password"
                            placeholder="Enter new password"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                        />
                        <p className="my-1 text-sm text-red-600 font-serif">
                            <ErrorMessage name="password"></ErrorMessage>
                        </p>

                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <Field
                            type="password"
                            name="confirmPassword"
                            placeholder="Enter new password again"
                            className="ring-1 hover:ring-[#0057FF] focus:ring-[#0057FF] rounded-md h-10"
                        />
                        <p className="my-1 text-sm text-red-600 font-serif">
                            <ErrorMessage name="confirmPassword"></ErrorMessage>
                        </p>

                        <span className="text-red-600">
                            {resetErrorMessage}
                        </span>

                        <button
                            type="submit"
                            className="cursor-pointer bg-blue-500 hover:bg-[#0057FF] rounded-md w-48 h-9 place-self-center text-white mt-2"
                        >
                            RESET PASSWORD
                        </button>
                    </Form>
                </Formik>

                <div className="flex justify-center cursor-pointer text-[#0057FF] mx-1">
                    <Link to="/login">
                        Return to login
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default ResetPassword;
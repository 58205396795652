/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import cn from "classnames";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { LoadingSpinner } from '../LoadingSpinner';
import { DAYS_IN_WEEK, DAYS_OF_WEEK, SCHEDULE_URL } from "../../config";

function Scheduler() {
    const [scheduledTimes, setScheduledTimes] = useState([...Array(DAYS_OF_WEEK.length).fill([])]);
    const [cookies, removeCookie] = useCookies();
    const [loader, setLoader] = useState(true)

    React.useEffect(() => {
        fetchSchedule();
    }, [])

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    function fetchSchedule() {
        setLoader(true)
        axios.get(
            SCHEDULE_URL,
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            if (response.status === 200) {
                setScheduledTimes(response.data.schedule);
                setLoader(false)
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status === 401) {
                removeCookie('SEARCHEDIN_AUTH_TOKEN');
            }
            console.log(error);
        });
    }

    function generateRandomData() {
        const scheduledTimesForWeek = [];

        for (let day = 0; day < DAYS_IN_WEEK; day++) {
            let index;
            const scheduledHoursInDay = [];
            for (let i = 0; i < Math.floor((Math.random() * 10) + 1); i++) {
                index = randomIntFromInterval(0, 23);
                if (!scheduledHoursInDay.includes(index)) {
                    scheduledHoursInDay.push(index);
                }
            }
            scheduledTimesForWeek.push(scheduledHoursInDay);
        }
        setScheduledTimes(scheduledTimesForWeek);
    }

    function updateSpecificDay(row, rowIndex, dayIndex, hourIndex) {
        if (rowIndex !== dayIndex) {
            return row;
        }
        return row.includes(hourIndex)
            ? row.filter(hour => hour !== hourIndex)
            : [...row, hourIndex];
    }

    function updateScheduledTimes(dayIndex, hourIndex) {
        setScheduledTimes(
            scheduledTimes.map((row, rowIndex) =>
                updateSpecificDay(row, rowIndex, dayIndex, hourIndex)
            )
        );
    }

    function resetScheduledTimes() {
        setScheduledTimes([...Array(DAYS_OF_WEEK.length).fill([])])
    }

    function defaultScheduledTimes() {
        setScheduledTimes([...Array(DAYS_OF_WEEK.length).fill([9, 12, 15, 18])])
    }

    function saveScheduledTimes() {
        setLoader(true)
        axios.post(
            SCHEDULE_URL,
            scheduledTimes,
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setLoader(false)
            }
        }).catch((error) => {
            setLoader(false)
            console.log(error);
        });
    }

    function getFormattedTextForHour(hourIndex) {
        return hourIndex !== 0
            ? <span className="w-8"> {hourIndex.toString().padStart(2, '0')}:00 </span>
            : <span className="w-8"></span>
    }

    return (
        <div className="h-screen flex flex-row justify-center items-center font-serif">
            {loader ? (
                <div className='p-4 m-4 md:p-32 md:m-32 flex flex-col justify-center items-center'>
                    {/* <h2 className='text-center font-serif font-weight-bold font-bold'>Loading...</h2> */}
                    <LoadingSpinner />
                </div>
            ) : (
            <div div className="flex flex-col items-center p-2 md:p-20 md:pt-10 gap-4 bg-white rounded-lg border border-[#0057FF]">
            
                <div className="flex flex-row gap-1.5">
                    {
                        scheduledTimes.map((_, dayIndex) =>
                            <div
                                key={`day${dayIndex}`}
                                className="flex flex-col gap-1 md:gap-1.5"
                            >
                                {
                                    dayIndex === 0
                                        ? <span className="w-18 text-center"> {DAYS_OF_WEEK[dayIndex]}</span>
                                        : DAYS_OF_WEEK[dayIndex]
                                }
                                {
                                    [...Array(24)].map((_, hourIndex) =>
                                        <div
                                            key={`hour${hourIndex}`}
                                            className="flex flex-row text-xs gap-2"
                                        >
                                            {
                                                dayIndex === 0
                                                    ? hourIndex !== 0
                                                        ? <span className="w-8"> {hourIndex.toString().padStart(2, '0')}:00 </span>
                                                        : <span className="w-8"></span>
                                                    : <></>
                                            }
                                            <div
                                                className={cn(
                                                    "w-10 md:w-16 md:h-4 h-5",
                                                    scheduledTimes[dayIndex].includes(hourIndex)
                                                        ? "bg-green-600 md:hover:bg-red-300"
                                                        : "bg-red-500 md:hover:bg-green-300"
                                                )}
                                                onClick={() =>
                                                    updateScheduledTimes(dayIndex, hourIndex)
                                                }
                                            >
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <br></br>
                <div className="flex gap-4">
                    <button
                        className="text-white p-2 rounded w-20 bg-blue-500 hover:bg-[#0057FF]"
                        onClick={generateRandomData}
                    >
                        Random
                    </button>
                    <button
                        className="text-white p-2 rounded w-20 bg-blue-500 hover:bg-[#0057FF]"
                        onClick={defaultScheduledTimes}
                    >
                        Default
                    </button>
                    <button
                        className="text-white p-2 rounded w-20 bg-blue-500 hover:bg-[#0057FF]"
                        onClick={resetScheduledTimes}
                    >
                        Reset
                    </button>
                    <button
                        className="text-white p-2 rounded w-20 bg-blue-500 hover:bg-[#0057FF]"
                        onClick={saveScheduledTimes}
                    >
                        Save
                    </button>
                </div>
            </div>
            )}
        </div >
    );
}

export default Scheduler;
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import cn from "classnames";
import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import { Edit, Link as LinkIcon, RefreshCcw, RotateCw, Search, X } from 'react-feather';
import { Route, useHistory } from "react-router-dom";
import codebaseImg from '../../assets/codebase_transparent.png';
import { DAILY_REFRESH_LIMIT_PER_KEYWORD, HISTORY_URL, REFRESH_URL, SEARCH_URL, SUGGESTIONS_URL, WEEKLY_REFRESH_LIMIT_PER_KEYWORD } from '../../config';
import { Brand } from '../Brand';
import { LinkedInLink } from '../LinkedInLink';
import './Navigation.css';

function Navigation({
    setSearchInitialize, activeSearchId, setActiveSearchId, displayPopup, setDisplayPopup, cookieExists
}) {
    const routeHistory = useHistory();
    const [activeKeyword, setActiveKeyword] = useState("Keywords");
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchHistory, setSearchHistory] = useState([{}]);
    const [suggestions, setSuggestions] = useState([{}]);
    const [editHistoryId, setEditHistoryId] = useState(null);
    const [cookies, , removeCookie] = useCookies();
    const [refreshSearchId, setRefreshSearchId] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showKeywordLoader] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
      };
    
      const handleOutsideClick = (event) => {
        if (event.target.closest('.dropdown')) return;
        setIsOpen(false);
      };
    

    React.useEffect(() => {
        console.log("Component is being re-rendered")
        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        } 
        else {
            document.removeEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);

    React.useEffect(() => {
        getHistory();
    }, []);

    React.useEffect(() => {
        if (!!activeSearchId) {
            const history = searchHistory.filter((history) => history.searchId === activeSearchId);
            if (!history.length) {
                setTimeout(() => {
                    axios.get(
                        HISTORY_URL,
                        { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
                    ).then((response) => {
                        if (response.status === 200) {
                            console.log("useEffect: ", response.data);
                            setSearchHistory(response.data?.history);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }, 1000);
            }
        }
    }, [activeSearchId]);

    React.useEffect(() => {
        if (searchText) {
            const timer = setTimeout(() => {
                axios.get(
                    `${SUGGESTIONS_URL}/${searchText}`,
                    { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
                ).then(
                    (response) => {
                        if (response.status === 200) {
                            setSuggestions(response.data);
                        }
                    }
                );
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [searchText])

    function extractQueryParams(queryParams) {
        let filter_params = {}

        if (queryParams.has("datePosted")) {
            filter_params['datePosted'] = JSON.parse(queryParams.get("datePosted"));
        }
        if (queryParams.has("sortBy")) {
            filter_params['sortBy'] = JSON.parse(queryParams.get("sortBy"));
        }
        if (queryParams.has("fromOrganization")) {
            filter_params['fromOrganization'] = JSON.parse(queryParams.get("fromOrganization"));
        }
        if (queryParams.has("authorIndustry")) {
            filter_params['authorIndustry'] = JSON.parse(queryParams.get("authorIndustry"));
        }
        if (queryParams.has("authorCompany")) {
            filter_params['authorCompany'] = JSON.parse(queryParams.get("authorCompany"));
        }
        if (queryParams.has("fromMember")) {
            filter_params['fromMember'] = JSON.parse(queryParams.get("fromMember"));
        }
        if (queryParams.has("postedBy")) {
            filter_params['postedBy'] = JSON.parse(queryParams.get("postedBy"));
        }
        if (queryParams.has("mentionsMember")) {
            filter_params['mentionsMember'] = JSON.parse(queryParams.get("mentionsMember"));
        }
        if (queryParams.has("mentionsOrganization")) {
            filter_params['mentionsOrganization'] = JSON.parse(queryParams.get("mentionsOrganization"));
        }
        if (queryParams.has("authorJobTitle")) {
            filter_params['authorJobTitle'] = JSON.parse(queryParams.get("authorJobTitle"));
        }
        return filter_params;
    }

    function extractInfoFromLink(link) {
        const queryParams = new URLSearchParams(link.split('?')[1]);

        if (queryParams.has("keywords")) {
            const parsedQueryParams = extractQueryParams(queryParams);
            let filter_params = {
                "keyword": queryParams.get("keywords"),
                ...parsedQueryParams
            };
            return filter_params;
        }
    }

    function getHistory() {
        axios.get(
            HISTORY_URL,
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            if (response.status === 200) {
                setSearchHistory(response.data?.history);
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('SEARCHEDIN_AUTH_TOKEN');
            }
            console.log(error);
        });
    }

    function newSearchHandler({
        keyword, linkText, link, dayLimit = DAILY_REFRESH_LIMIT_PER_KEYWORD,
        weekLimit = WEEKLY_REFRESH_LIMIT_PER_KEYWORD
    }) {
        let searchParams = {
            keyword: keyword
        }
        if (linkText && link) {
            searchParams = extractInfoFromLink(link);
            searchParams['linkText'] = linkText;
            searchParams['dayLimit'] = dayLimit;
            searchParams['weekLimit'] = weekLimit;
        }

        axios.post(
            SEARCH_URL,
            searchParams,
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setDisplayPopup(false);
                setSearchInitialize(true);
                setActiveSearchId(response.data.activeSearchId);
                getHistory();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    function historyClickHandler(activeSearchId) {
        setSearchInitialize(true);
        setActiveSearchId(activeSearchId);
        setActiveKeyword(searchHistory.filter(x => x.searchId === activeSearchId)[0].keyword)
        setIsOpen(false)
    }

    function deleteSearchHandler(searchId) {
        axios.delete(
            `${HISTORY_URL}/${searchId}`,
            { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
        ).then((response) => {
            if (response.status === 204) {
                setSearchInitialize(false);
                setActiveSearchId(null);
                setSearchHistory(searchHistory.filter((history) => history.searchId !== searchId));
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    function refreshSearchHandler(searchId) {
        setRefreshSearchId(searchId);
        setShowLoader(true);

        axios({
            method: 'post',
            url: `/${searchId}`,
            baseURL: REFRESH_URL,
            headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` }
        }).then((response) => {
            if (response.status === 200) {
                setRefreshSearchId(null);
                setShowLoader(false);
                console.log("Refreshed");
            }
        }).catch((error) => {
            if (error.response.status === 404) {
                setShowLoader(false);
            }
            console.log(error);
        });
    }

    console.log(activeKeyword)
    return (
        < div
            className='flex flex-row md:flex-col md:full bg-[#0057FF] rounded-md w-full md:w-[20rem] lg:w-[24rem] h-10 md:h-full items-stretch'
            role='button'
            tabIndex={0}
            onClick={() => {
                if (suggestions) {
                    setSuggestions([]);
                }
            }}
        >
            {
                displayPopup &&
                <LinkedInLink
                    editHistoryId={editHistoryId}
                    setEditHistoryId={setEditHistoryId}
                    setDisplayPopup={(flag) => setDisplayPopup(flag)}
                    newSearchHandler={newSearchHandler}
                />
            }

            <Brand
                setSearchInitialize={setSearchInitialize}
                setActiveSearchId={setActiveSearchId}
                clickable={true}
            />

            <Route exact path="/dashboard">
                <div className='relative'>
                    <div className='flex flex-row mr-1 md:mr-2 ml-4 md:ml-8 justify-center items-center'>
                        <div className='flex flex-row content-center rounded-2xl w-[6rem] md:w-[14rem] lg:w-[20rem] bg-white' style={{ marginTop: "3px" }}>
                            <Search strokeWidth={1} className='top-1 left-4 m-1 pl-auto md:pl-0 cursor-text align-middle' />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchText}
                                disabled={displayPopup || !cookieExists}
                                className={cn(
                                    ' placeholder:text-xs placeholder:md:text-base w-[5rem] md:w-[12rem] lg:w-[16rem] h-7 md:h-8 rounded-r-2xl',
                                    displayPopup && "cursor-text"
                                )}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        newSearchHandler({ keyword: e.target.value });
                                        setSearchText("");
                                    }
                                }}
                            />
                        </div>
                        {
                            showKeywordLoader &&
                            <RotateCw className='ml-1.5 mt-1.5 animate-spin' size={20} />
                        }
                        <LinkIcon
                            className="hidden md:block cursor-pointer ml-1.5 mt-1.5"
                            size={20}
                            onClick={() => setDisplayPopup(true)}
                        />
                        <LinkIcon
                            className="md:hidden cursor-pointer ml-1 mt-1.5"
                            size={15}
                            onClick={() => setDisplayPopup(true)}
                        />
                    </div>
                    {
                        suggestions &&
                        <div className="absolute divide-y mr-2 ml-8 my-0.5 rounded-md w-[12rem] md:w-[14rem] lg:w-[20rem] bg-white">
                            {
                                suggestions.map((suggest) =>
                                    <h2
                                        key={suggest.searchId}
                                        className='cursor-pointer ml-1 max-w-xs break-words'
                                        onClick={(e) => {
                                            setSearchInitialize(true);
                                            setSuggestions([]);
                                            setActiveSearchId(suggest.searchId);
                                            setSearchText("");
                                        }}
                                    >
                                        {suggest.linkText ? suggest.linkText : suggest.keyword}
                                    </h2>
                                )
                            }
                        </div>
                    }
                </div>

                <div className='flex flex-row  md:flex-col md:h-1/2 ml-auto mr-2 md:mx-8 md:my-10 items-stretch'>
                    <h1 className='hidden md:inline not-italic font-[Poppins] text-[#3C7EFF] mx-1 my-2 text-xl md:text-2xl font-medium'>
                        Searches
                    </h1>
                    <div className='hidden md:block md:h-full overflow-auto'>
                        {
                            searchHistory.map((history) =>
                                <div
                                    key={history.searchId}
                                    className='group history'
                                >
                                    <span
                                        onClick={() => historyClickHandler(history.searchId)}
                                        className={cn(
                                            'text-lg md:text-xl font-medium pb-2 mr-2',
                                            activeSearchId === history.searchId
                                                ? "text-[#00000066]" : "text-white"
                                        )}
                                    >
                                        {history.linkText ? history.linkText : history.keyword}
                                    </span>
                                    {
                                        history.linkText &&
                                        <Edit
                                            className='hidden cursor-pointer ml-1 group-hover:inline-block'
                                            strokeWidth={1}
                                            size={18}
                                            onClick={() => {
                                                setEditHistoryId(history.searchId);
                                                setDisplayPopup(true);
                                            }}
                                        />
                                    }
                                    <RefreshCcw
                                        className={cn(
                                            'mt-0.5 ml-1 group-hover:inline-block',
                                            (refreshSearchId === history.searchId && showLoader)
                                                ? "inline animate-spin"
                                                : "hidden"
                                        )}
                                        strokeWidth={1}
                                        size={16}
                                        onClick={() => refreshSearchHandler(history.searchId)}
                                    />
                                    <X
                                        className="hidden cursor-pointer mt-0.5 ml-1 group-hover:inline-block"
                                        strokeWidth={1}
                                        size={18}
                                        value={history.searchId}
                                        onClick={() => deleteSearchHandler(history.searchId)}
                                    />
                                </div>
                            )
                        }
                    </div>


                    <div className='md:hidden ml-auto w-full'>
                        <div className='dropdown justify-center items-center w-full' style={{marginTop:"3px"}}>
                        
                            <button className="w-[7rem] font-monospace text-[#3C7EFF] bg-white rounded-2xl  font-medium  text-sm px-1.5 py-1 text-center inline-flex items-center" type="button" onClick={handleClick}>
                                <span className="py-0.5 px-1 truncate">{activeKeyword}</span>
                                <svg className="ml-auto h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                                
                            </button>
                        
                            {
                                isOpen &&
                                (
                                <div className="z-10 bg-[#0057FF]  shadow w-full flex-wrap">
                                    <ul className="w-[7rem] bg-[#0057FF] absolute z-10 border-2 rounded-lg text-gray-700 overflow-y-auto text-base max-h-64">
                                    {
                                    searchHistory.map((history) =>
                                    <li>
                                        <div
                                            key={history.searchId}
                                            className='flex group flex-wrap content-center w-full text-white'
                                        >
                                            <p
                                                onClick={() => historyClickHandler(history.searchId)}
                                                className={cn(
                                                    'truncate text-s font-mono   font-medium mb-1 mr-1 w-3/5',
                                                    activeSearchId === history.searchId
                                                        ? "text-[#00000066]" : "text-white"
                                                )}
                                            >
                                                {history.linkText ? history.linkText : history.keyword}
                                            </p>
                                            
                                            {/* <RefreshCcw
                                                className={cn('ml-1 mt-1 pb-1 group-hover:inline-block',
                                                    (refreshSearchId === history.searchId && showLoader)
                                                        ? "inline animate-spin"
                                                        : "hidden"
                                                )}
                                                style={{color:'black'}}
                                                strokeWidth={1}
                                                size={16}
                                                onClick={() => refreshSearchHandler(history.searchId)}
                                            /> */}
                                            <X
                                                className={cn(isOpen?'ml-1 mt-1 pb-1 inline-block':'hidden ml-1 mt-1 pb-1 group-hover:inline-block')}
                                                strokeWidth={1}
                                                size={15}
                                                value={history.searchId}
                                                onClick={() => deleteSearchHandler(history.searchId)}
                                            />
                                            {
                                                history.linkText &&
                                                <Edit
                                                    className={cn(isOpen?'ml-1 mt-1 pb-1 inline-block':'hidden ml-1 mt-1 pb-1 group-hover:inline-block')}
                                                    strokeWidth={1}
                                                    size={15}
                                                    onClick={() => {
                                                        setEditHistoryId(history.searchId);
                                                        setDisplayPopup(true);
                                                    }}
                                                />
                                            }
                                        </div>
                                    </li>
                                        
                                    )
                                }
                                    </ul>
                                </div>
                                )
                            }
                        </div>
                    

                    </div>
                    
                </div>

                <div className='hidden md:inline-block navigationFooter ml-8'>
                    <div
                        className='flex flex-row justify-between cursor-pointer text-[#3C7EFF] hover:text-blue-100'
                        onClick={() => routeHistory.push('/settings')}
                    >
                        <span className='text-2xl font-[Poppins]' >
                            Settings
                        </span>
                    </div>

                    <div className='flex flex-row items-center mg-1'>
                        <span className='text-sm md:text-base text-white'>Powered by</span>
                        <a href='https://www.codebase.com/' target="_blank" rel="noreferrer">
                            <img src={codebaseImg} className='w-20 h-8 md:w-40 md:h-10' alt="" />
                        </a>
                    </div>
                </div>

            </Route>
        </div >
    )
}

export default Navigation;
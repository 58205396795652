import { Circle } from 'react-feather';
import cn from 'classnames';


function Brand({ setSearchInitialize, setActiveSearchId, clickable }) {
    return (
        <div>
            <div className='hidden md:flex flex-row justify-center my-8'>
                <div className='flex items-end'>
                    <Circle
                        size={12}
                        fill="white" strokeWidth={4} color='white'
                    />
                </div>
                <div className='flex items-center'>
                    <Circle
                        size={30}
                        fill="#0057FF" stroke="#FFFFFF" strokeWidth={4} color='white'
                    />
                </div>
                <strong
                    className={cn(
                        'font-[Inter] text-white not-italic ml-2 text-xl md:text-3xl',
                        clickable && 'cursor-pointer'
                    )}
                    onClick={() => {
                        clickable && setSearchInitialize(false);
                        clickable && setActiveSearchId(null);
                    }}
                >
                    SearchedIn
                </strong>
            </div>
            <div className='md:hidden flex flex-row justify-center my-1 mx-1'>
                <div className='flex items-end'>
                    <Circle
                        size={8}
                        fill="white" strokeWidth={4} color='white'
                    />
                </div>
                <div className='flex items-center'>
                    <Circle
                        size={20}
                        fill="#0057FF" stroke="#FFFFFF" strokeWidth={4} color='white'
                    />
                </div>
                <strong
                    className={cn(
                        'font-[Inter] text-white not-italic ml-1 text-xl md:text-3xl',
                        clickable && 'cursor-pointer'
                    )}
                    onClick={() => {
                        clickable && setSearchInitialize(false);
                        clickable && setActiveSearchId(null);
                    }}
                >
                    SearchedIn
                </strong>
            </div>
        </div>



    )
}

export default Brand;
export const TABS = ["Live", "Starred", "Archived"];
export const DEFAULT_ACTIVE_TAB = TABS.length ? TABS[0] : "";

export const DISPLAY_SEARCH_RESULT_COUNT = 5;
export const DAYS_IN_WEEK = 7
export const HOURS_IN_DAY = 24
export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const DAILY_USER_LIMIT = 50
export const WEEKLY_USER_LIMIT = 250
export const DAILY_REFRESH_LIMIT_PER_KEYWORD = 4
export const WEEKLY_REFRESH_LIMIT_PER_KEYWORD = 20

export let API_BASE_URL = "http://127.0.0.1:5000/api/v1";
if (process.env.NODE_ENV === "production") {
    API_BASE_URL = "https://mrwarby.com/api/v1"
}

export const SEARCH_URL = `${API_BASE_URL}/search`;
export const POSTS_COUNT_URL = `${API_BASE_URL}/posts_count`;
export const REFRESH_URL = `${SEARCH_URL}/refresh`;
export const HISTORY_URL = `${API_BASE_URL}/history`;
export const SCHEDULE_URL = `${API_BASE_URL}/schedule`;
export const HISTORY_DETAILS_URL = `${HISTORY_URL}/details`;
export const SCHEDULE_DETAILS_URL = `${HISTORY_URL}/details`;
export const MARK_POST_URL = `${API_BASE_URL}/update_post`;
export const MARK_POSTS_URL = `${API_BASE_URL}/update_posts`;
export const SUGGESTIONS_URL = `${HISTORY_URL}/suggestions`;
export const COOKIES_URL = `${API_BASE_URL}/cookies`;
export const USER_RATELIMIT_URL = `${API_BASE_URL}/rate_limit`;
export const KEYWORD_RATELIMIT_URL = `${API_BASE_URL}/keyword_rate_limits`;
export const LOGIN_URL = `${API_BASE_URL}/login`;
export const REGISTER_URL = `${API_BASE_URL}/register`;
export const FORGOT_PASSWORD_URL = `${API_BASE_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${API_BASE_URL}/reset-password`;
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { DAILY_REFRESH_LIMIT_PER_KEYWORD, DAILY_USER_LIMIT, KEYWORD_RATELIMIT_URL, USER_RATELIMIT_URL, WEEKLY_REFRESH_LIMIT_PER_KEYWORD, WEEKLY_USER_LIMIT } from "../../config";
import { LoadingSpinner } from '../LoadingSpinner';

const RateLimiter = () => {
  const [dayLimit, setDayLimit] = useState(50);
  const [weekLimit, setWeekLimit] = useState(250);
  const [keywordData, setkeywordData] = useState([]);
  const [userDataLoader, setUserDataLoader] = useState(true);
  const [keywordDataLoader, setKeywordDataLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [keywordErrorMessage, setkeywordErrorMessage] = useState("");
  const [cookies] = useCookies();

  function fetchUserLimits() {
    setUserDataLoader(true)
    axios
      .get(USER_RATELIMIT_URL,
        { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } })
      .then(res => {
        setDayLimit(res.data.userDayLimit);
        setWeekLimit(res.data.userWeekLimit);
        setUserDataLoader(false);
      })
      .catch(error => {
        console.error(error);
        setUserDataLoader(false);
      });
  }

  function fetchKeywordLimits() {
    setKeywordDataLoader(true);
    axios
      .get(KEYWORD_RATELIMIT_URL,
        { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } })
      .then(res => {
        setkeywordData(res.data.keywords);
        setKeywordDataLoader(false);
      })
      .catch(error => {
        console.error(error);
        setKeywordDataLoader(false);
      });
  }

  useEffect(() => {
    // Fetch the initial values from the backend
    fetchUserLimits()
    fetchKeywordLimits()
  }, []);

  const handleDayLimitChange = (event) => {
    if (event.target.value < 0 || event.target.value > DAILY_USER_LIMIT) {
      setErrorMessage(`Daily User Limit should be between 0 and ${DAILY_USER_LIMIT}`);
    }
    else {
      setErrorMessage("")
      setDayLimit(event.target.value);
    }
  };

  const handleWeekLimitChange = (event) => {
    if (event.target.value < 0 || event.target.value > WEEKLY_USER_LIMIT) {
      setErrorMessage(`Weekly User Limit should be between 0 and ${WEEKLY_USER_LIMIT}`);
    }
    else {
      setErrorMessage("")
      setWeekLimit(event.target.value);
    }
  };

  // const handleSave = () => {
  //   let errorMessage = '';
  //   if (dayLimit>DAILY_USER_LIMIT || dayLimit<0)
  //     errorMessage = 'Daily Limit should be between 0 and ${DAILY_USER_LIMIT}'
  //     setErrorMessage(errorMessage);
  //   } else if (weekLimit < 0 || weekLimit > WEEKLY_USER_LIMIT) {
  //     setErrorMessage("Second Value should be between 0 and 100");
  //   } else {
  //     setErrorMessage("");

  //   axios.post('/api/updateValues', {
  //     value1: dayLimit,
  //     value2: weekLimit,
  //   });
  // };

  const handleSave = () => {
    // if (dayLimit < 0 || dayLimit > DAILY_USER_LIMIT) {
    //   setErrorMessage(`Daily Limit should be between 0 and ${DAILY_USER_LIMIT}`);
    // } else if (weekLimit < 0 || weekLimit > WEEKLY_USER_LIMIT) {
    //   setErrorMessage(`Weekly Limit should be between 0 and ${WEEKLY_USER_LIMIT}`);
    // } else {
    //   setErrorMessage("");
    setUserDataLoader(true);
    axios.patch(
      USER_RATELIMIT_URL,
      { "userDayLimit": dayLimit, "userWeekLimit": weekLimit },
      { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
    ).then((res) => {
      console.log(res.data);
      setUserDataLoader(false);
    })
      .catch((err) => {
        console.error(err);
        setUserDataLoader(false);
      });
    // }
  };

  const handleEdit = (id, key, value) => {
    if ((key === 'dailyLimit') && (value < 0 || value > DAILY_REFRESH_LIMIT_PER_KEYWORD)) {
      setkeywordErrorMessage(`Daily Keyword Refresh Limit should be between 0 and ${DAILY_REFRESH_LIMIT_PER_KEYWORD}`);
    } else if ((key === 'weeklyLimit') && (value < 0 || value > WEEKLY_REFRESH_LIMIT_PER_KEYWORD)) {
      setkeywordErrorMessage(`Weekly Keyword Refresh Limit should be between 0 and ${WEEKLY_REFRESH_LIMIT_PER_KEYWORD}`);
    } else {
      setkeywordErrorMessage("");
      // setUserDataLoader(true);
      setkeywordData(prevData =>
        prevData.map(item => {
          if (item.searchId === id) {
            return { ...item, [key]: value };
          }
          return item;
        })
      );
    }
  };

  const handleKeywordDataSave = (id, key, value) => {
    setKeywordDataLoader(true);
    axios.patch(
      KEYWORD_RATELIMIT_URL,
      keywordData,
      { headers: { 'Authorization': `bearer ${cookies['SEARCHEDIN_AUTH_TOKEN']}` } }
    ).then((res) => {
      console.log(res.data);
      setKeywordDataLoader(false);
    })
      .catch((err) => {
        console.error(err);
        setKeywordDataLoader(false);
      });
  };



  return (
    <div class='h-screen flex flex-col md:flex-row justify-center items-center font-serif'>
      {
        userDataLoader
          ? (
            <div className='p-20 m-20 flex flex-col justify-center items-center'>
              {/* <h2 className='text-center font-serif font-weight-bold font-bold'>Loading...</h2> */}
              <LoadingSpinner />
            </div>
          )
          : (
            <div className='p-2 md:p-8 flex flex-col justify-center items-center'>

          <h2 className='text-center font-[Poppins] font-weight-bold font-bold'>User Refresh Limits</h2>
          <br></br>
          <table class="shadow-lg bg-white border-separate">
            <thead>
              <tr>
                <th class="bg-blue-200 font-[Poppins] border text-left px-8 py-4">Daily Limit</th>
                <th class="bg-blue-200 font-[Poppins] border text-left px-8 py-4">Weekly Limit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border px-8 py-4">
                  <input
                    type="number"
                    value={dayLimit}
                    min={0}
                    max={DAILY_USER_LIMIT}
                    className='text-center bg-center justify-center'
                    onChange={handleDayLimitChange}
                  />
                </td>
                <td class="border px-8 py-4">
                  <input
                    type="number"
                    value={weekLimit}
                    min={0}
                    max={WEEKLY_USER_LIMIT}
                    className='text-center'
                    onChange={handleWeekLimitChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-red-500 m-2">{errorMessage}</div>


          <div className="flex justify-center m-2">
            <button
              className="bg-blue-500 hover:bg-[#0057FF] w-20 text-white p-2 rounded"
              onClick={handleSave}
            >
              Save
            </button>
          </div>

            </div>
          )
      }

      <br className='hidden md:block' ></br>
      <br className='hidden md:block'></br>
      {
        keywordDataLoader
          ? (
            <div className='p-20 m-20 flex flex-col justify-center items-center'>
              {/* <h2 className='text-center font-serif font-weight-bold font-bold'>Loading...</h2> */}
              <LoadingSpinner />
            </div>
          )
          : (
            <div className='p-2 md:p-8 flex flex-col justify-center items-center'>

          <h2 className='text-center font-[Poppins] font-weight-bold font-bold'>Keyword Refresh Limits</h2>
          <br></br>
          <table class="shadow-lg bg-white border-separate table-auto overflow-auto h-full block">
            <thead>
              <tr>
                <th class="bg-blue-200 font-[Poppins] border text-left px-2 py-2 md:px-8 md:py-4">Keyword</th>
                <th class="bg-blue-200 font-[Poppins] border text-left px-2 py-2 md:px-8 md:py-4">Daily Limit</th>
                <th class="bg-blue-200 font-[Poppins] border text-left px-2 py-2 md:px-8 md:py-4">Weekly Limit</th>
              </tr>
            </thead>
            <tbody>
              {keywordData.map(item => (
                <tr key={item.searchId}>
                  <td class="border px-2 py-2 md:px-8 md:py-4">{item.keyword}</td>
                  <td class="border px-2 py-2 md:px-8 md:py-4">
                    <input
                      type="number"
                      value={item.dailyLimit}
                      min={0}
                      max={DAILY_REFRESH_LIMIT_PER_KEYWORD}
                      className='text-center bg-center justify-center'
                      onChange={e => handleEdit(item.searchId, 'dailyLimit', e.target.value)}
                    />
                  </td>
                  <td class="border px-2 py-2 md:px-8 md:py-4">
                    <input
                      type="number"
                      value={item.weeklyLimit}
                      min={0}
                      max={WEEKLY_REFRESH_LIMIT_PER_KEYWORD}
                      className='text-center'
                      onChange={e => handleEdit(item.searchId, 'weeklyLimit', e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-red-500 m-2">{keywordErrorMessage}</div>


          <div className="flex justify-center m-2">
            <button
              className="bg-blue-500 w-20 text-white p-2 rounded"
              onClick={handleKeywordDataSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RateLimiter;